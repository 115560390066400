// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  API_URL: 'https://webapp231538.ip-45-79-173-198.cloudezapp.io/api/PreFornecedor',
  API_ROOTURL: 'https://webapp231538.ip-45-79-173-198.cloudezapp.io/api',
  
  // ADN 68
  // API_URL: 'http://10.99.1.68:8308/api/PreFornecedor',
  // API_ROOTURL: 'http://10.99.1.68:8308/api',

  //PRODUÇÃO OMNICOM
  // API_URL: 'https://webapp231538.ip-45-79-173-198.cloudezapp.io/api/PreFornecedor',
  // API_ROOTURL: 'https://webapp231538.ip-45-79-173-198.cloudezapp.io/api',
  
  //HOMOLOGAÇÃO OMNICOM
  // API_URL: 'https://webapp322798.ip-45-79-22-180.cloudezapp.io/api/PreFornecedor',
  // API_ROOTURL: 'https://webapp322798.ip-45-79-22-180.cloudezapp.io/api',

  // HOMOLOGAÇÃO DAN
  // API_URL: 'http://br-test-precadastro-adn-api.americas.media.global.loc/api/PreFornecedor',
  // API_ROOTURL: 'http://br-test-precadastro-adn-api.americas.media.global.loc/api',

  // PRODUÇÃO DAN
  // API_URL: 'https://portalcadastro-api.dentsu.com/api/PreFornecedor',
  // API_ROOTURL: 'https://portalcadastro-api.dentsu.com/api',
  
  firebase: {
    apiKey: 'YOUR_APIKEY',
    authDomain: 'YOUR_AUTH_DOMAIN',
    databaseURL: 'YOUR_DATABASE_URL',
    projectId: 'YOUR_PROJECT_ID',
    storageBucket: 'universal-angular-85d7e.appspot.com',
    messagingSenderId: 'YOUR_MESSAGE_SENDER_ID',
    appId: 'YOUR_APPID'
  }
};
 